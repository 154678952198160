import AudioHowl from '@phoenix7dev/play-music';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ISongs, mappedAudioSprites, SlotId } from '../config';
import {
  BetBonusReward,
  bonusesId,
  EventTypes,
  GameMode,
  ISettledBet,
  reelSets,
  UserBonus,
} from '../global.d';
import {
  client,
  getUserBonuses,
  isStoppedGql,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
  slotBetGql,
} from '../gql';
import {
  formatNumber,
  getSpinResult,
  isFreeSpinMode,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../utils';
import AnimationGroup from './animations/animationGroup';
import Animator from './animations/animator';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME,
  ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE,
  ANTICIPATION_SYMBOLS_ID,
  APPLICATION_TRANSPARENT,
  eventManager,
  FREE_SPINS_TIME_OUT_BANNER,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  SlotMachineState,
} from './config';
import { Icon, ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import {
  additionalPosition,
  subtitlePosition,
  titlePosition,
  winSubtitlePosition,
  winTitlePosition,
} from './messageBanner/config';
import {
  additionalStyle,
  subtitleStyle,
  titleStyle,
  winSubtitleStyle,
  winTitleStyle,
} from './messageBanner/textStyles';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsContainer from './reels/reelsContainer';
import RetriggerMessage from './retrigger/retriggerMessage';
import SafeArea from './safeArea/safeArea';
import Slot from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  public animator: Animator;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public gameView: GameView;

  public reelsContainer: ReelsContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private rounds: number;

  private constructor(
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      transparent: APPLICATION_TRANSPARENT,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });
    this.initEventListeners();
    this.application.stage.sortableChildren = true;
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.animator = new Animator(this.application);
    let { startPosition } = slotData.settings;
    let reelSet;

    startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    setPrevReelsPosition(startPosition);
    // eslint-disable-next-line prefer-const
    reelSet = setUserLastBetResult().id
      ? slotData.reels.find(
          (reelSet) => reelSet.id === setUserLastBetResult().reelSetId,
        )!
      : slotData.reels[0];
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);

    this.miniPayTableContainer = new MiniPayTableContainer(
      slotData.icons,
      this.getSlotById.bind(this),
    );
    this.miniPayTableContainer.setSpinResult(
      getSpinResult({
        reelPositions: startPosition.slice(0, 5),
        reelSet,
        icons: slotData.icons,
      }),
    );
    this.gameView = this.initGameView(slotData);
    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.rounds = setCurrentBonus().rounds;
    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    if (setBrokenGame()) {
      this.onBrokenGame();
    }
  }

  private async onBrokenGame(): Promise<void> {
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: setCurrentBonus().betId } },
      fetchPolicy: 'network-only',
    });

    const gameMode = this.getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(
      EventTypes.UPDATE_TOTAL_WIN_VALUE,
      setCurrentFreeSpinsTotalWin(),
    );
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: i18n.t('freeSpinsTitle'),
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });
    if (setProgress().wasLoaded) {
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        this.setState(SlotMachineState.IDLE);
      });
    }
  }

  private getGameModeByBonusId(bonusId: string): GameMode {
    if (bonusId === bonusesId[GameMode.FREE_SPINS]) return GameMode.FREE_SPINS;

    return GameMode.REGULAR;
  }

  private initPixiLayers() {
    this.application.stage.addChild(
      new Background(),
      new BottomContainer(),
      new Backdrop(),
      this.initSafeArea(),
      new FadeArea(),
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initGameView(slotData: ISlotData): GameView {
    const gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      linesContainer: new LinesContainer(slotData.lines),
      reelsContainer: this.reelsContainer,
      tintContainer: new TintContainer(),
      winLabelContainer: new WinLabelContainer(),
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
    });

    gameView.interactive = true;
    gameView.on('mousedown', () => this.skipAnimations());
    gameView.on('touchstart', () => this.skipAnimations());

    return gameView;
  }

  private initEventListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );

    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REGISTER_ANIMATOR,
      this.registerAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REMOVE_ANIMATOR,
      this.removeAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_RETRIGGER_FEATURE,
      this.onRetriggerEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
  }) {
    setGameMode(settings.mode);
    setReelSetId(settings.reelSetId);

    const reelSet = setSlotConfig().reels.find(
      (reels) => reels.id === settings.reelSetId,
    );

    const spinResult = getSpinResult({
      reelPositions: settings.reelPositions.slice(0, 5),
      reelSet: reelSet!,
      icons: setSlotConfig().icons,
    });
    this.miniPayTableContainer.setSpinResult(spinResult);
    eventManager.emit(EventTypes.CHANGE_REEL_SET, {
      reelSet: setSlotConfig().reels.find(
        (reels) => reels.id === settings.reelSetId,
      ),
      reelPositions: settings.reelPositions,
    });
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonusId('');
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        ),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, false);
      this.setState(SlotMachineState.IDLE);

      // AudioHowl.stop({ type: ISongs.FreeSpinBGM_Intro });
      AudioHowl.stop({ type: ISongs.FreeSpinBGM_Loop });
      AudioHowl.play({ type: ISongs.BaseGameBGM_Base });
      AudioHowl.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });
    } else if (isFreeSpinMode(settings.mode)) {
      eventManager.emit(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, true);
      eventManager.emit(EventTypes.SET_WIN_LABEL_TEXT, 'total win');
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );
      setCurrentBonusId(setCurrentBonus().id);

      eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
        text: i18n.t('freeSpinsTitle'),
        spins: '10',
        currentSpin: '0',
      });
      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: {
            key: 'freeSpinsMessageBannerTitle',
          },
          titlePosition,
          titleStyles: titleStyle,
          subtitle: {
            key: 'freeSpinsMessageBannerSubtitle',
            var: setCurrentBonus().rounds,
          },
          subtitlePosition,
          subtitleStyles: subtitleStyle,
          btnText: 'freeSpinsMessageBannerBtnText',
          callback: () => {
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        this.setState(SlotMachineState.IDLE);
      }

      // bgm
      // const introDelay = Tween.createDelayAnimation(
      //   mappedAudioSprites[ISongs.FreeSpinBGM_Intro].duration,
      // );
      AudioHowl.stop({ type: ISongs.BaseGameBGM_Base });
      AudioHowl.stop({ type: ISongs.BaseGameBGM_Melo });
      // AudioHowl.play({ type: ISongs.FreeSpinBGM_Intro });
      // introDelay.addOnComplete(() => {
      // const { volume } = AudioHowl.getSoundByKey(ISongs.FreeSpinBGM_Intro);
      AudioHowl.play({ type: ISongs.FreeSpinBGM_Loop });
      // });
      // introDelay.start();
    }
  }

  // todo implement start free spins
  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    const mode = GameMode.FREE_SPINS;
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[mode],
    });
  }

  // todo implement start free spins
  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonusId() } },
      fetchPolicy: 'network-only',
    });
    const bonus = res.data.userBonuses[0];
    // todo replace with real backend logic
    const { betId } = res.data.userBonuses[0];
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });
    const { reelPositions, winCountAmount, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    };

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioHowl.fadeOut(1000, ISongs.FreeSpinBGM_Loop);
    AudioHowl.play({
      type: ISongs.TotalWinBanner,
      stopPrev: true,
      event: {
        type: 'end',
        callback: () => {
          AudioHowl.fadeIn(1000, ISongs.FreeSpinBGM_Loop);
        },
        soundKey: ISongs.TotalWinBanner,
      },
    });

    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        reelPositions,
      });
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: { key: 'freeSpinsMessageBannerWinText' },
        titlePosition: winTitlePosition,
        titleStyles: winTitleStyle,
        subtitle: {
          key: `${formatNumber(
            setCurrency(),
            normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          )}`,
        },
        subtitlePosition: winSubtitlePosition,
        subtitleStyles: winSubtitleStyle,
        preventDefaultDestroy: true,
        callback,
      });
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
        title: { key: 'freeSpinsMessageBannerWinText' },
        titlePosition: winTitlePosition,
        titleStyles: winTitleStyle,
        subtitle: {
          key: `${formatNumber(
            setCurrency(),
            normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          )}`,
        },
        subtitlePosition: winSubtitlePosition,
        subtitleStyles: winSubtitleStyle,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private registerAnimator(animator: () => void) {
    this.application.ticker.add(animator);
  }

  private removeAnimator(animator: () => void) {
    this.application.ticker.remove(animator);
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current,
      total,
    );
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === reelSets[setGameMode()],
        ),
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.isReadyForStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        this.removeErrorHandler();
        this.dynamicReelSetChange();
        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.bet.result.reelPositions,
          this.getScatterCount(this.nextResult.bet.result.spinResult),
          this.getAnticipationReelId(this.nextResult.bet.result.spinResult),
        );
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(
        !isFreeSpinMode(setGameMode()) && !!isTurboSpin,
      );

      if (isFreeSpinMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        setCurrentBonus(bonus);
        this.updateFreeSpinsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
        eventManager.emit(
          EventTypes.SHOW_FREE_SPINS_MULTIPLIER_ANIMATION,
          setCurrentBonus().currentRound,
        );
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const mode = setGameMode();
    const bonus = setCurrentBonus();
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(
        isTurboSpin,
      );
      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationReelId(this.nextResult.bet.result.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private isFreeSpins(): boolean {
    return !!(this.nextResult?.rewards.find(
      // eslint-disable-next-line no-underscore-dangle
      (reward) => reward.__typename === 'BetBonusReward',
    ) as BetBonusReward)?.userBonus;
  }

  private onCountUpEnd(): void {
    const mode = setGameMode();
    if (this.isFreeSpins()) {
      if (mode === GameMode.REGULAR) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        this.rounds = this.nextResult!.bet.data.bonuses[0].rounds;
        setCurrentBonus({
          ...this.nextResult!.bet.data.bonuses[0],
          isActive: true,
          currentRound: 0,
        });
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        this.startFreeSpins();
      }
      if (isFreeSpinMode(mode)) {
        // on retrigger
        AudioHowl.play({ type: ISongs.FeatureReTrigger, stopPrev: true });

        setCurrentFreeSpinsTotalWin(
          setCurrentFreeSpinsTotalWin() +
            this.nextResult!.bet.result.winCoinAmount,
        );
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
        this.updateFreeSpinsAmount(
          setCurrentBonus().currentRound,
          setCurrentBonus().rounds,
        );
        const additionalFreeSpins = setCurrentBonus().rounds - this.rounds;
        this.gameView.addChild(new RetriggerMessage(additionalFreeSpins));
        eventManager.emit(EventTypes.START_RETRIGGER_FEATURE);
        this.rounds = setCurrentBonus().rounds;
        return;
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
      }
      if (isFreeSpinMode(mode)) {
        setCurrentFreeSpinsTotalWin(
          setCurrentFreeSpinsTotalWin() +
            this.nextResult!.bet.result.winCoinAmount,
        );
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
      }
    }
    this.setState(SlotMachineState.IDLE);
  }

  private onRetriggerEnd(): void {
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = isFreeSpinMode(setGameMode())
        ? ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE[i]
        : ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        // eslint-disable-next-line no-plusplus
        if (spinResult[j].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

        if (currentCount >= count) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId;
  }

  private getScatterCount(spinResult: Array<Icon>): Array<number> {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (
          col.some((icon) => icon.id === SlotId.SC1 || icon.id === SlotId.SC2)
        ) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    this.nextResult = result;
    setPrevReelsPosition(result.bet.result.reelPositions);
    if (!isFreeSpinMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.placed,
      );
    }
    if (result.bet.data.bonuses.length) {
      setCurrentBonus({
        ...setCurrentBonus(),
        rounds:
          result.bet.data.bonuses[0].rounds +
          result.bet.data.bonuses[0].roundsPlayed,
      });
    }
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(
      this.nextResult!.bet.result.spinResult,
    );
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length -
        this.reelsContainer.reels[x].position +
        y -
        1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinMode(setGameMode()) ? false : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.isFreeSpins() && !isFreeSpinMode(setGameMode())) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.FeatureTrigger].duration,
        );
        jingleDelay.addOnStart(() => {
          AudioHowl.play({ type: ISongs.FeatureTrigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if (!isFreeSpinMode(GameMode.FREE_SPINS)) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
        }
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
